import React from "react"
import SmallCalculator from "../components/Organisms/Calculator/SmallCalculator"
import ContactForm from "../components/Molecules/ContactForm/ContactForm"
import { WithIntl } from "../components/Atoms/withTranslate"
import SEO from "../components/seo"

const CalculatorPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title="Kalkulator rentowności - oblicz zysk z myjni bezdotykowej"
      description="Sprawdź, na jaki dochód możesz liczyć w naszym kalkulatorze rentowności myjni bezdotykowej. Zapraszamy do wyliczenia potencjalnego zysku!"
    />
    <SmallCalculator/>
    <div className="container">
      <ContactForm/>
    </div>
  </>
)

export default WithIntl()(CalculatorPage)
